export default  {
    methods: {
        validateForm: function(form) {
            for (var field in this[form].fields) {
            	if (this[form].fields.hasOwnProperty(field)) {
            		this.validateField(form, field);
            	}
            }
            return this.checkForm(form);
        },
        validateField: function(form, field) {
            var rules = this[form].fields[field].rules,
                value = this[form].fields[field].value;
            this[form].fields[field].validation = approve.value(value, rules);
            return this[form].fields[field].validation.approved;
        },
        checkForm: function(form) {
            var valid = true;
            for (var field in this[form].fields) {
                if (this[form].fields.hasOwnProperty(field)) {
                    if (!this[form].fields[field].validation.approved) {
                        valid = false;
                    }
                }
            }
            this[form].valid = valid;
            return valid;
        },
        checkField: Vue.debounce(function(form, field) {
            return this[form].fields[field].validation.approved;
        }, 500),
        getFormData: function(form) {
            var data = {};
            for (var field in this[form].fields) {
                if (this[form].fields.hasOwnProperty(field)) {
                    data[field] = this[form].fields[field].value;
                }
            }
            return data;
        },
        clearForm: function(form) {
            for (var field in this[form].fields) {
                if (this[form].fields.hasOwnProperty(field)) {
                    this[form].fields[field].value = '';
                }
            }
        },
        fillForm: function(form, obj) {
            this[form].disabled = true;
            for (var field in this[form].fields) {
                if (this[form].fields.hasOwnProperty(field)) {
                    this[form].fields[field].value = obj[field];
                }
            }
            // this[form].disabled = false;
        },
        refreshForm: function(form) {
            for (var field in this[form].fields) {
                if (this[form].fields.hasOwnProperty(field)) {
                    this[form].fields[field].value = document.getElementById(field).value;
                }
            }
            this.checkForm(form);
        },
        disableForm: function(form) {
            this[form].validate = false;
            this[form].disabled = true;
        },
        enableForm: function(form) {
            this[form].validate = true;
            this[form].disabled = false;
        },
        submitForm: function(form, attach, resolve, reject) {
            if (this.validateForm(form)) {
                var _self = this;
                this[form].submitting = true;
                var data = this.getFormData(form);
                if (attach) {
                    for (var item in attach) {
                        if (attach.hasOwnProperty(item)) {
                            data[item] = attach[item];
                        }
                    }
                }
                return Vue.api.switchToVersion(this[form].version).post(this[form].post, data).then(function(response) {
                    _self[form].submitting = false;
                    //_self.clearForm(form);
                    resolve(response);
                }, function(response) {
                    _self[form].submitting = false;
                    reject(response);
                });
            }
        }
    }
};