export default  {
    data: {
        loginForm: {
            id: 'login-form',
            disabled: false,
            valid: false,
            validate: false,
            submitting: false,
            fields: {
                email: {
                    value: '',
                    validation: {
                        approved: false,
                        errors: []
                    },
                    rules: {
                        title: 'Email',
                        required: true,
                        email: true
                    }
                },
                password: {
                    value: '',
                    validation: {
                        approved: false,
                        errors: []
                    },
                    rules: {
                        title: 'Password',
                        required: true
                    }
                }
            }
        }
    },
    methods: {
        submitLoginForm: function() {
            var _self = this;
            
        }
    }
};